import { Link } from '@remix-run/react';
import { useNavigate } from '@remix-run/react';
import parseInt from 'lodash/parseInt';
import { useTitle } from 'react-use';
import useSWRImmutable from 'swr/immutable';

import { useUserAnalytics } from '../analytics/user';
import {
  buildSearchParamsWithRedirectToAsString,
  usePostLogin,
  useRedirectTo,
  UserVerifyModel,
} from '../components/Access';
import { LoginHeader, LoginModalLayoutV2 } from '../components/Access';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { GlobalLoading } from '../components/GlobalLoading';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import { useQueryParam } from '../hooks/useQueryParam';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';

enum VerifyType {
  Invitation = 1,
  Login,
  LoginGenerateByAdmin,
  LoginSlackConnect,
  Registration,
}

function InvalidLinkModal() {
  const redirectTo = useRedirectTo();

  return (
    <LoginModalLayoutV2>
      <div className='absolute text-white w-full flex justify-center'>
        <LoginHeader noSlogan />
        <div
          className='w-max h-full flex flex-col items-center bg-black
    bg-opacity-80 border border-secondary rounded-2.25xl 
    py-10 px-5 lg:px-15 gap-10'
        >
          <div className='w-80 lg:w-100 h-60 flex flex-col items-center justify-center gap-4'>
            <div className='font-bold'>Invalid or expired link</div>
            <div className='text-sms text-center'>
              <p>
                Sorry, this link has either already been used or has expired.
              </p>
              <p>
                If you feel this is a mistake, please contact your account
                administrator, or reach out to us{' '}
                <a
                  href='https://lunapark.com/contact/'
                  target='_blank'
                  rel='noreferrer'
                >
                  here
                </a>{' '}
                to create an account.
              </p>
            </div>
            <div className='font-medium text-sms text-icon-gray'>
              <Link
                to={{
                  pathname: '/login',
                  search: buildSearchParamsWithRedirectToAsString(redirectTo),
                }}
              >
                Go to Login Options
              </Link>
            </div>
          </div>
        </div>
      </div>
    </LoginModalLayoutV2>
  );
}

function UserVerifyCallbackRegistration() {
  const code = useQueryParam('code') || '';
  const postLogin = usePostLogin();
  const navigate = useNavigate();

  const { error } = useSWRImmutable(['/user-verify', code], async () => {
    if (!code) return;

    // NOTE: the code will not be deleted from this api call.
    // /onboarding/activation will take care of deleting the code.
    const resp = await apiService.auth.verifyCallback({
      code,
    });
    postLogin(resp.data);

    navigate(
      {
        pathname: '/onboarding/activation',
        search: window.location.search,
      },
      {
        replace: true,
      }
    );
    return;
  });

  if (!code || error) {
    return <InvalidLinkModal />;
  }
  return <GlobalLoading />;
}

function Container() {
  const verifyType = parseInt(useQueryParam('verify-type') || '');

  switch (verifyType) {
    case VerifyType.Registration:
      return <UserVerifyCallbackRegistration />;
    case VerifyType.Invitation:
      return <UserVerifyModel verifyType={'invite'} />;
    default:
      return <UserVerifyModel verifyType={'login'} />;
  }
}

export function Component() {
  useTitle(makeTitle('Sign In'));

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Container />

      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}
